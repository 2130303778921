@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply bg-background text-foreground border-border;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  height: 100%;
}

@layer base {
  :root {
    --background: #131316;
    --foreground: #EEEEEC;
    --muted-foreground: #ABA9A3;
    --light:#016FEE;
    --card: #1c1c1c;
    --border: #202020;
  }
  
  .dark {
    --background: #131316;
    --foreground: #EEEEEC;
    --muted-foreground: #ABA9A3;
    --light:#016FEE;
    --card: #1c1c1c;
    --border: #202020;
  }
}
